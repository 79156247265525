body {
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.csm-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: rgb(48, 47, 47);
}
.multiselect {
  margin-bottom: 280px;
}
.enterpriseForm {
  height: 800px;
  overflow: auto;
  padding: 3px;
}
.box {
  width: 40%;
  display: flex;
  flex-direction: column; /* Arrange the items in a single column */
  align-items: center; /* Center the items horizontally */
}
.button {
  margin-top: 15px;
  text-align: center;
}

.initial-icon-container {
  display: flex;
  background-color: green;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.initial-icon-text {
  color: white;
  font-size: 25px;
  margin: 0;
}
.navbar p {
  margin: auto 10px;
}

.alert {
  position: absolute;
  top: 5px;
  left: 50%; /* Center the left edge of the alert to the middle of the page */
  transform: translateX(
    -50%
  ); /* Pull alert back by half its width to truly center it */
  z-index: 9999;
  text-align: center;
}

.input-text-align {
  text-align: center;
}

@media (max-width: 800px) {
  .alert {
    top: 90px;
  }
}
@media (max-width: 600px) {
  .csm-container {
    width: 400px; /* Adjust this value as needed */
  }
}

@media (max-width: 400px) {
  .csm-container {
    width: 350px; /* Adjust this value as needed */
  }
}

@media only screen and (max-width: 1537px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1281px) {
  html {
    font-size: 12px;
  }
}

.pointer {
  cursor: pointer;
}
